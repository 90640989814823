/* .swiper {
	width: 100px;
	height: 300px;
} */
.swiper {
	margin-left: 0;
	margin-right: 0;
}
.swiper-w {
	width: 100% !important;
	margin-right: 0 !important;
}
.swiper-button-prev,
.swiper-button-next {
	color: #5cb85c;
}
